// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cricket-calendar-js": () => import("./../../../src/pages/cricket/calendar.js" /* webpackChunkName: "component---src-pages-cricket-calendar-js" */),
  "component---src-pages-cricket-coaching-js": () => import("./../../../src/pages/cricket/coaching.js" /* webpackChunkName: "component---src-pages-cricket-coaching-js" */),
  "component---src-pages-cricket-girls-js": () => import("./../../../src/pages/cricket/girls.js" /* webpackChunkName: "component---src-pages-cricket-girls-js" */),
  "component---src-pages-cricket-girlsgallery-js": () => import("./../../../src/pages/cricket/girlsgallery.js" /* webpackChunkName: "component---src-pages-cricket-girlsgallery-js" */),
  "component---src-pages-cricket-girlshonours-js": () => import("./../../../src/pages/cricket/girlshonours.js" /* webpackChunkName: "component---src-pages-cricket-girlshonours-js" */),
  "component---src-pages-cricket-history-js": () => import("./../../../src/pages/cricket/history.js" /* webpackChunkName: "component---src-pages-cricket-history-js" */),
  "component---src-pages-cricket-honours-js": () => import("./../../../src/pages/cricket/honours.js" /* webpackChunkName: "component---src-pages-cricket-honours-js" */),
  "component---src-pages-cricket-index-js": () => import("./../../../src/pages/cricket/index.js" /* webpackChunkName: "component---src-pages-cricket-index-js" */),
  "component---src-pages-cricket-jogle-js": () => import("./../../../src/pages/cricket/jogle.js" /* webpackChunkName: "component---src-pages-cricket-jogle-js" */),
  "component---src-pages-cricket-junior-js": () => import("./../../../src/pages/cricket/junior.js" /* webpackChunkName: "component---src-pages-cricket-junior-js" */),
  "component---src-pages-cricket-juniorgallery-js": () => import("./../../../src/pages/cricket/juniorgallery.js" /* webpackChunkName: "component---src-pages-cricket-juniorgallery-js" */),
  "component---src-pages-cricket-juniorhonours-js": () => import("./../../../src/pages/cricket/juniorhonours.js" /* webpackChunkName: "component---src-pages-cricket-juniorhonours-js" */),
  "component---src-pages-cricket-nets-js": () => import("./../../../src/pages/cricket/nets.js" /* webpackChunkName: "component---src-pages-cricket-nets-js" */),
  "component---src-pages-cricket-news-js": () => import("./../../../src/pages/cricket/news.js" /* webpackChunkName: "component---src-pages-cricket-news-js" */),
  "component---src-pages-cricket-policies-js": () => import("./../../../src/pages/cricket/policies.js" /* webpackChunkName: "component---src-pages-cricket-policies-js" */),
  "component---src-pages-cricket-previousseason-js": () => import("./../../../src/pages/cricket/previousseason.js" /* webpackChunkName: "component---src-pages-cricket-previousseason-js" */),
  "component---src-pages-cricket-selection-js": () => import("./../../../src/pages/cricket/selection.js" /* webpackChunkName: "component---src-pages-cricket-selection-js" */),
  "component---src-pages-cricket-senior-js": () => import("./../../../src/pages/cricket/senior.js" /* webpackChunkName: "component---src-pages-cricket-senior-js" */),
  "component---src-pages-cricket-seniorgallery-js": () => import("./../../../src/pages/cricket/seniorgallery.js" /* webpackChunkName: "component---src-pages-cricket-seniorgallery-js" */),
  "component---src-pages-cricket-seniorhistory-js": () => import("./../../../src/pages/cricket/seniorhistory.js" /* webpackChunkName: "component---src-pages-cricket-seniorhistory-js" */),
  "component---src-pages-cricket-seniorhonours-js": () => import("./../../../src/pages/cricket/seniorhonours.js" /* webpackChunkName: "component---src-pages-cricket-seniorhonours-js" */),
  "component---src-pages-cricket-sponsors-js": () => import("./../../../src/pages/cricket/sponsors.js" /* webpackChunkName: "component---src-pages-cricket-sponsors-js" */),
  "component---src-pages-cricket-statistics-js": () => import("./../../../src/pages/cricket/statistics.js" /* webpackChunkName: "component---src-pages-cricket-statistics-js" */),
  "component---src-pages-cricket-women-js": () => import("./../../../src/pages/cricket/women.js" /* webpackChunkName: "component---src-pages-cricket-women-js" */),
  "component---src-pages-cricket-womengallery-js": () => import("./../../../src/pages/cricket/womengallery.js" /* webpackChunkName: "component---src-pages-cricket-womengallery-js" */),
  "component---src-pages-cricket-womenhistory-js": () => import("./../../../src/pages/cricket/womenhistory.js" /* webpackChunkName: "component---src-pages-cricket-womenhistory-js" */),
  "component---src-pages-cricket-womenhonours-js": () => import("./../../../src/pages/cricket/womenhonours.js" /* webpackChunkName: "component---src-pages-cricket-womenhonours-js" */),
  "component---src-pages-cricket-youthhonours-js": () => import("./../../../src/pages/cricket/youthhonours.js" /* webpackChunkName: "component---src-pages-cricket-youthhonours-js" */),
  "component---src-pages-football-js": () => import("./../../../src/pages/football.js" /* webpackChunkName: "component---src-pages-football-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-social-contact-js": () => import("./../../../src/pages/social/contact.js" /* webpackChunkName: "component---src-pages-social-contact-js" */),
  "component---src-pages-social-contact-success-js": () => import("./../../../src/pages/social/contact-success.js" /* webpackChunkName: "component---src-pages-social-contact-success-js" */),
  "component---src-pages-social-events-js": () => import("./../../../src/pages/social/events.js" /* webpackChunkName: "component---src-pages-social-events-js" */),
  "component---src-pages-social-fieldgallery-js": () => import("./../../../src/pages/social/fieldgallery.js" /* webpackChunkName: "component---src-pages-social-fieldgallery-js" */),
  "component---src-pages-social-gallery-js": () => import("./../../../src/pages/social/gallery.js" /* webpackChunkName: "component---src-pages-social-gallery-js" */),
  "component---src-pages-social-index-js": () => import("./../../../src/pages/social/index.js" /* webpackChunkName: "component---src-pages-social-index-js" */),
  "component---src-pages-social-pitp-js": () => import("./../../../src/pages/social/pitp.js" /* webpackChunkName: "component---src-pages-social-pitp-js" */),
  "component---src-pages-social-pitpgallery-js": () => import("./../../../src/pages/social/pitpgallery.js" /* webpackChunkName: "component---src-pages-social-pitpgallery-js" */),
  "component---src-pages-social-venue-js": () => import("./../../../src/pages/social/venue.js" /* webpackChunkName: "component---src-pages-social-venue-js" */)
}

